<template>
  <div class="profile-info" :class="{_loading: pendingLoad}">
    <BasePreloader v-if="pendingLoad || pendingUpdate"/>
    <form class="form">
      <BaseInput v-model="inputs.name.value"
                 :error="inputs.name.error"
                 :label="$t(`Ім'я`)"
                 class="form__input"/>
      <BaseInput v-model="inputs.phone.value"
                 :error="inputs.phone.error"
                 :label="$t('Телефон')"
                 type="tel"
                 :mask="{mask: '+{38} ({\\0}#0)-000-00-00',
                      definitions: {'#': /[1-9]/},
                      unmask: true
                    }"
                 class="form__input"/>
      <BaseInput v-model="inputs.email.value"
                 :error="inputs.email.error"
                 :label="$t('Електронна пошта')"
                 type="email"
                 class="form__input"/>
<!--      <BaseInput v-model="inputs.address.value"-->
<!--                 :error="inputs.address.error"-->
<!--                 :label="$t('Адреса')"-->
<!--                 class="form__input"/>-->
      <DatePickerInput v-model="inputs.birth_date.value"
                       :error="inputs.birth_date.error"
                       :label="$t('День народження')"
                       v-bind="dateOptions"
                       class="form__input _picker"
      />
      <div class="">
        <div class="form__label">{{ $t('Стать') }}:</div>
        <BaseRadioList :value="inputs.sex.value"
                       :error="inputs.sex.error"
                       @input="setGender"
                       :options="genderList"
                       class="form-basket__radio-group"
        />
      </div>
      <AlertInline :value="overallErrorsMix"
                   class="_warning"/>
      <div class="form__footer">
        <button @click.prevent="onSave"
                class="form__btn  base-btn">
          {{$t('Зберегти')}}
        </button>
        <router-link :to="{name: 'account-profile', params: {savePosition: true}}"
                     class="form__btn  base-btn _secondary">{{$t('Скасувати')}}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
  import formMixins from '../mixins/formMixins'
  import AlertInline from './AlertInline'
  import DatePickerInput from './DatePickerInput'
  import {mapState} from 'vuex'

  export default {
    name: 'ProfileInfoEdit',
    mixins: [formMixins],
    components: {
      DatePickerInput,
      AlertInline,
    },
    data() {
      return {
        inputs: {
          name: {
            value: null
          },
          phone: {
            value: null
          },
          email: {
            value: null
          },
          sex: {
            value: null
          },
          //address: {
          //  value: null
          //},
          birth_date: {
            value: null
          }
        }
      }
    },
    computed: {
      ...mapState('user', [
        'pendingLoad',
        'pendingUpdate'
      ]),
      dateOptions() {
        return {
          format: 'DD.MM.YYYY',
          editable: true,
          defaultValue: '2000-01-01',
          disabledDate: this.disabledDate,
          defaultPanel: 'year'
        }
      },
      genderList() {
        return this.$store.state.genderList.reduce((result, item) => {
          item = {...item}
          item.name = this.$t(item.name)
          result.push(item)
          return result
        }, [])
      }
    },
    methods: {
      setGender(value) {
        this.$set(this.inputs.sex, 'value', value)
      },
      disabledDate(date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date > today
      },
      onSave() {
        this.resetErrorsMix(this.inputs)

        const sendData = this.createSendDataMix(this.inputs)

        if(sendData.birth_date) {
          sendData.birth_date = sendData.birth_date.toLocaleString('uk-UA')
        }

        this.$store.dispatch('user/updateProfile', sendData).then(() => {
          this.$vModal.open('message', {
            message: this.$t('Особисті дані успішно збережено')
          })
          this.$router.push({name: 'account-profile'})
        }).catch(error => {
          this.handlerErrorMix(error, this.inputs)
        })
      }
    },
    created() {
      this.$store.dispatch('user/load').then((profile) => {
        for (const key in this.inputs) {
          let value = profile[key]
          if (value) {
            if (key === 'phone') {
              value = value.replace(/^380|^\+380|^0/g, '')
            }
            if (key === 'birth_date') {
              value = new Date(value)
            }
            if (key === 'sex') {
              value = this.genderList.find(item => item.id === value) || null
            }
            this.$set(this.inputs[key], 'value', value)
          }
        }
      });
    }
  }
</script>

<style>

</style>
