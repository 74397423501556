<template>
  <div class="profile-info" :class="{_loading: pendingLoad}">
    <BasePreloader v-if="pendingLoad || pendingUpdate"/>
    <form class="form">
      <BaseInput v-model="inputs.old_password.value"
                 :error="inputs.old_password.error"
                 type="password"
                 :label="$t('Введіть старий пароль')"
                 class="form__input"/>
      <BaseInput v-model="inputs.new_password.value"
                 :error="inputs.new_password.error"
                 type="password"
                 :label="$t('Введіть новий пароль')"
                 class="form__input"/>
      <BaseInput v-model="inputs.confirm_new_password.value"
                 :error="inputs.confirm_new_password.error"
                 type="password"
                 :label="$t('Повторіть новий пароль')"
                 class="form__input"/>
      <AlertInline :value="overallErrorsMix"
                   class="_warning"/>
      <div class="form__footer">
        <button @click.prevent="onSave"
                class="form__btn  base-btn">
          {{$t('Зберегти')}}
        </button>
        <router-link :to="{name: 'account-profile'}"
                     class="form__btn  base-btn _secondary">{{$t('Скасувати')}}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
  import formMixins from '../mixins/formMixins'
  import AlertInline from './AlertInline'
  import {mapState} from 'vuex'

  export default {
    name: 'PasswordEdit',
    mixins: [formMixins],
    components: {
      AlertInline
    },
    data() {
      return {
        inputs: {
          old_password: {
            value: null
          },
          new_password: {
            value: null
          },
          confirm_new_password: {
            value: null
          }
        }
      }
    },
    computed: {
      ...mapState('user', [
        'pendingLoad',
        'pendingUpdate'
      ]),
    },
    methods: {
      onSave() {
        this.resetErrorsMix(this.inputs)

        const sendData = this.createSendDataMix(this.inputs)

        this.$store.dispatch('user/changePassword', sendData).then(() => {
          this.$vModal.open('message', {
            message: this.$t('Пароль успішно змінено'),
          })
          this.$router.push({name: 'account-profile'})
        }).catch(error => {
          this.handlerErrorMix(error, this.inputs)
        })
      }
    }
  }
</script>

<style>

</style>
