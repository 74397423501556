<template>
  <div class="account-menu">
    <ul class="account-menu__list">
      <li class="account-menu__list-item"
          v-for="item in links"
          :key="item.id">
        <router-link :to="{name: item.nameUrl}"
                     class="account-menu__link">
          {{ item.name }}
          <b v-if="item.id === 3 && unread_count">+{{ unread_count }}</b>
        </router-link>
      </li>
      <li class="account-menu__list-item" v-if="bonuses_available">
        <router-link :to="{name: 'loyalty'}"
                     class="account-menu__link">{{ $t('Ваші бонуси') }}:
          <b>{{ profile && profile.available_bonuses || 0 | formatPrice }} {{ $t('грн') }}</b></router-link> <!--todo: need fix used_bonuses to bonuses_available on backend-->
      </li>
      <li class="account-menu__list-item">
        <a href="#" class="account-menu__link" @click.prevent="onLogout">
          <BaseSvg name="ico-exit"/>
          {{ $t('Вихід') }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'AccountMenu',
    data() {
      return {
        links: [
          {
            id: 1,
            name: this.$t('Особисті дані'),
            nameUrl: 'account-profile'
          },
          {
            id: 2,
            name: this.$t('Мої замовлення'),
            nameUrl: 'account-orders'
          },
          {
            id: 3,
            name: this.$t('Повідомлення'),
            nameUrl: 'account-messages'
          },
          {
            id: 4,
            name: this.$t('Змінити пароль'),
            nameUrl: 'account-password-edit'
          }
        ]
      }
    },
    computed: {
      ...mapState('user', [
        'profile',
        'pendingLoad',
        'pendingUpdate'
      ]),
      ...mapState('notifications', [
        'unread_count'
      ]),
      ...mapState({
        bonuses_available(state) {
          return JSON.parse(JSON.stringify(this.$get(state.settings.settings.orders, 'bonuses_available', false)))
        }
      })
    },
    methods: {
      onLogout() {
        this.$store.dispatch('user/logout')
      }
    }
  }
</script>

<style>

</style>
