<template>
  <li class="orders-history__item" :class="{ _open: isOpen }">
    <div class="orders-history__item-btn" @click="onToggle">
      <BaseSvg name="ico-arrow-down" />

      <div class="orders-history__item-btn-left">
        <div class="orders-history__item-btn-id">№ {{ item.id }}</div>
        <div class="orders-history__item-btn-status">
          <!--<span>{{ item.created_at | formatDate }}</span> /-->
          <BaseStatus
            :status="item.last_status.status_code"
            :status-text="item.last_status.status"
            :status-comment="item.last_status.comment"
          />

          <router-link
            v-if="!item.rated && item.status_code === 4"
            :to="{
              name: 'feedback',
              query: { order_id: item.id },
            }"
            class="base-btn _small"
            >{{ $t("Оцінити") }}
          </router-link>

          <div v-else-if="item.status_code === 4" class="orders-history__rated">
            <div class="orders-history__feedback-stars">
              <BaseSvg
                name="ico-star"
                class="orders-history__feedback-icon"
                :class="{ _active: checkIsActiveStar(rate, item) }"
                v-bind:key="rate"
                v-for="rate of [1, 2, 3, 4, 5]"
              />
            </div>

            <div class="orders-history__feedback-edit" @click.stop="handleEditReviews(item)">
              {{ $t("Редагувати") }}
            </div>
          </div>
        </div>
        <div class="orders-history__item-btn-info">
          <template v-if="item.delivery_type_id === DELIVERY_METHOD_ADDRESS"> {{ $t("Час доставки") }}:</template>
          <template v-else-if="item.delivery_type_id === DELIVERY_METHOD_SELF"> {{ $t("Час самовивозу") }}:</template>
          <template v-if="last_time">
            <span :style="item.last_status.time ? 'text-decoration: line-through;' : null"
              >{{ item.time | formatDate }} {{ formatTime(item.time) }}</span
            >&nbsp;
            <span v-if="item.last_status.time">
              {{ item.last_status.time | formatDate }} {{ formatTime(item.last_status.time) }}</span
            >
          </template>
          <span v-else-if="item.quickly && item.delivery_type_id === DELIVERY_METHOD_ADDRESS">{{
            $t("Блискавично").toLocaleLowerCase()
          }}</span>
          <span v-else> - </span>
        </div>

        <div
          v-if="
            item.delivery_type_id === DELIVERY_METHOD_ADDRESS &&
            item.quickly &&
            last_time &&
            ![4, 5].includes(item.last_status.status_code)
          "
          class="orders-history__item-btn-info"
        >
          <Countdown
            v-if="totalMilliseconds > 0"
            class="orders-history__item-btn-countdown"
            @updateTotal="onCountUpdate"
            :class="{ _danger: isTimeNearEnd }"
            can-shrink
            :time="timeLeft"
          />
          <span v-else class="orders-history__item-btn-countdown _danger">{{ $t("Час вийшов") }}</span>
        </div>
      </div>
      <div class="orders-history__item-btn-right">
        {{ $t("Сума") }}: <b>{{ item.total_for_payment | formatPrice }} {{ $t("грн") }}</b>
      </div>
    </div>

    <collapse-transition>
      <div v-if="isOpen">
        <div class="orders-history__item-collapse">
          <ul class="orders-history__products-list">
            <li
              v-for="(product, index) in item.order_products"
              :key="`${index}-${product.id}`"
              class="orders-history__product history-product"
            >
              <router-link
                :to="{ name: 'product', params: { id: product.product.slug || product.product.id } }"
                class="history-product__img"
              >
                <img :src="$imgPlaceholder" class="lazyload" :data-src="product.product.image_xs" :alt="product.name" />
              </router-link>

              <div class="history-product__content">
                <h4 class="history-product__name">
                  <router-link :to="{ name: 'product', params: { id: product.product.slug || product.product.id } }"
                    >{{ product.name }}
                  </router-link>
                  &nbsp;
                  <span class="history-product__name-quantity">x{{ product.quantity }}</span>
                </h4>
                <div v-if="parseItem(product.ingredients).length" class="history-product__params">
                  <h5 class="history-product__label">{{ $t("Додаткові інгредієнти") }}:</h5>
                  &nbsp;
                  <span
                    v-for="(ingredient, index) in parseItem(product.ingredients)"
                    :key="`${index}-${ingredient.ingredient_id}`"
                  >
                    {{ ingredient.name }}
                    ({{ ingredient.price }} {{ $t("грн") }} x {{ ingredient.quantity }})</span
                  >
                </div>
                <div v-if="parseItem(product.properties)" class="history-product__params">
                  <h5 class="history-product__label">{{ $t("Соус") }}:</h5>
                  &nbsp;
                  <span
                    >{{ parseItem(product.properties).name }} ({{ parseItem(product.properties).price }}
                    {{ $t("грн") }})</span
                  >
                </div>
                <ul class="history-product__info">
                  <li>
                    <span
                      ><b>{{ $t("Ціна") }}:</b> <i>{{ product.price | formatPrice }} {{ $t("грн") }}</i></span
                    >&nbsp;
                    <span v-if="+product.packing_price"
                      >+ <i>{{ product.packing_price | formatPrice }} {{ $t("грн") }}</i>
                      <sub>({{ $t("пакування") }})</sub></span
                    >
                  </li>
                  <li>
                    <span
                      ><b>{{ $t("Разом") }}:</b> <i>{{ product.total | formatPrice }} {{ $t("грн") }}</i></span
                    >
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <div class="orders-history__footer-content">
            <div class="orders-history__footer-item">
              <span class="orders-history__footer-item-label">{{ $t("Товари") }}:</span>
              <span class="orders-history__footer-item-value">{{ item.sum | formatPrice }} {{ $t("грн") }}</span>
            </div>
            <div class="orders-history__footer-item" v-if="item.packing && Number(item.packing)">
              <span class="orders-history__footer-item-label">{{ $t("Пакування") }}:</span>
              <span class="orders-history__footer-item-value">{{ item.packing | formatPrice }} {{ $t("грн") }}</span>
            </div>
            <div class="orders-history__footer-item">
              <span class="orders-history__footer-item-label">{{ $t("Доставка") }}:</span>
              <span class="orders-history__footer-item-value">{{ item.delivery | formatPrice }} {{ $t("грн") }}</span>
            </div>
            <div class="orders-history__footer-item" v-if="item.paid_with_bonuses && Number(item.paid_with_bonuses)">
              <span class="orders-history__footer-item-label">{{ $t("Використано бонусів") }}:</span>
              <span class="orders-history__footer-item-value"
                >- {{ item.paid_with_bonuses | formatPrice }} {{ $t("грн") }}</span
              >
            </div>
            <div class="orders-history__footer-item _total">
              <span class="orders-history__footer-item-label">{{ $t("Разом до оплати") }}:</span>
              <span class="orders-history__footer-item-value"
                >{{ item.total_for_payment | formatPrice }} {{ $t("грн") }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </collapse-transition>
  </li>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";
import formatTime from "@/helpers/formatTime";
import { DELIVERY_METHOD_ADDRESS, DELIVERY_METHOD_SELF } from "@/constants/deliveryMethods";
import Countdown from "@/components/Countdown";

const MILLISECONDS_SECOND = 1000;
const MILLISECONDS_MINUTE = 60 * MILLISECONDS_SECOND;

export default {
  name: "OrdersHistoryItem",
  props: {
    item: {
      type: Object,
    },
  },
  components: {
    Countdown,
    CollapseTransition,
  },
  data() {
    return {
      DELIVERY_METHOD_ADDRESS,
      DELIVERY_METHOD_SELF,
      formatTime,
      isOpen: false,
      totalMilliseconds: 0,
    };
  },
  computed: {
    last_time() {
      return this.$get(this.item, "last_status.time") || this.$get(this.item, "time") || "";
    },
    timeLeft() {
      const expires_at = new Date(this.last_time);
      const now = new Date();
      const res = expires_at - now;
      return res > 0 ? res : 0;
    },
    isTimeNearEnd() {
      return this.totalMilliseconds <= MILLISECONDS_MINUTE * 5;
    },
  },
  created() {
    this.totalMilliseconds = this.timeLeft;
  },
  watch: {
    timeLeft: {
      immediate: true,
      handler() {
        this.totalMilliseconds = this.timeLeft;
      },
    },
  },
  methods: {
    onToggle() {
      this.isOpen = !this.isOpen;
    },
    parseItem(ingredients) {
      if (typeof ingredients === "string") {
        return JSON.parse(ingredients);
      }
      return ingredients;
    },
    onCountUpdate(time) {
      this.totalMilliseconds = time;
    },
    checkIsActiveStar(rate, item) {
      return rate <= item.reviews[0].rate;
    },
    handleEditReviews(item) {
      window.localStorage.setItem("oldReviews", JSON.stringify(item.reviews));
      if (item.comment) {
        window.localStorage.setItem("oldComment", JSON.stringify(item.comment[item.comment.length - 1]?.comment) || "");
      }

      this.$router.push({
        name: "feedback",
        query: {
          order_id: item.id,
          edit: true,
        },
      });
    },
  },
};
</script>

<style></style>
