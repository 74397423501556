<template>
  <div class="account-item">
    <div class="account-item__head">
      <h3 class="account-item__title">{{$t('Повідомлення')}}</h3>
    </div>

    <Messages/>
  </div>
</template>

<script>

  import Messages from '@/components/Messages'
  export default {
    name: 'AccountPageMessages',
    components: {Messages},
    data() {
      return {}
    }
  }
</script>

<style>

</style>
