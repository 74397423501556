<template>
  <div class="profile-info" :class="{_loading: pendingLoad}">
    <BasePreloader v-if="pendingLoad || pendingUpdate"/>
    <template v-if="profile">
      <h4 class="profile-info__name" v-if="profile.name">{{ profile.name }}</h4>
      <table class="profile-info__table">
        <tbody>
          <tr>
            <td>{{ $t('Телефон') }}:</td>
            <td>{{ profile.phone && reformatUserPhone(profile.phone) || '-' }}</td>
          </tr>
          <tr>
            <td>{{ $t('Електронна пошта') }}:</td>
            <td>{{ profile.email || '-' }}</td>
          </tr>
<!--          <tr>-->
<!--            <td>{{ $t('Адреса') }}:</td>-->
<!--            <td>{{ profile.address || '-' }}</td>-->
<!--          </tr>-->
          <tr>
            <td>{{ $t('День народження') }}:</td>
            <td>
              <template v-if="profile.birth_date">{{ profile.birth_date | formatDate }}</template>
              <template v-else>-</template>
            </td>
          </tr>
          <tr>
            <td>{{ $t('Стать') }}:</td>
            <td>
              <template v-if="profile.sex">{{ getGender(profile.sex) }}</template>
              <template v-else>-</template>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'ProfileInfo',
    computed: {
      ...mapState('user', [
        'profile',
        'pendingLoad',
        'pendingUpdate'
      ])
    },
    methods: {
      getGender(value) {
        const gender = this.$store.state.genderList.find(item => item.id === value)
        return gender ? this.$t(gender.name) : null
      },
      reformatUserPhone(value) {
        let cleanedPhone = value.replace(/[^0-9]+/g, '');
        return "+" + cleanedPhone.substring(0, 3) + " " + cleanedPhone.substring(3, 5) + " " + cleanedPhone.substring(5, 8) + " " + cleanedPhone.substring(8, 10) + " " + cleanedPhone.substring(10, 12);
      }
    }
  }
</script>

<style>

</style>
