<template>
  <div class="account-item">
    <div class="account-item__head">
      <h3 class="account-item__title">{{$t('Редагувати особисті дані')}}</h3>
    </div>

    <ProfileInfoEdit class="account-item__content"/>
  </div>
</template>

<script>
  import ProfileInfoEdit from '@/components/ProfileInfoEdit'
  export default {
    name: 'AccountPageProfileEdit',
    components: {ProfileInfoEdit}
  }
</script>

<style>

</style>
