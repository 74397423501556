<template>
  <div class="account-item">
    <div class="account-item__head">
      <h3 class="account-item__title">{{$t('Особисті дані')}}</h3>
      <router-link :to="{name: 'account-profile-edit', params: {savePosition: true}}" class="account-item__edit base-btn">{{$t('Редагувати')}}</router-link>
    </div>

    <ProfileInfo class="account-item__content"/>
  </div>
</template>

<script>
  import ProfileInfo from '../../components/ProfileInfo'
  export default {
    name: 'AccountPageProfile',
    components: {ProfileInfo}
  }
</script>

<style>

</style>
