<template>
  <li class="messages__item" :class="{ _open: isOpen, _unread: isUnread }">
    <div class="messages__item-btn" @click="onToggle">
      <div class="messages__item-btn-content">
        <div class="messages__item-btn-row">
          <BaseSvg class="messages__item-btn-ico" name="ico-calendar" />
          {{ item.created_at | formatDate }} {{ formatTime(item.created_at) }}
        </div>
        <div class="messages__item-btn-row">
          <BaseSvg v-if="isUnread" class="messages__item-btn-ico" name="ico-bell" />
          <BaseSvg v-else class="messages__item-btn-ico" name="ico-bell-stroke" />
          {{ item.header }}
        </div>
      </div>
      <BaseSvg class="messages__item-btn-arrow" name="ico-arrow-down" />
    </div>
    <collapse-transition>
      <div v-if="isOpen">
        <div class="messages__item-collapse">
          <div class="messages__item-text text">
            <p>{{ item.message.body || item.message.message }}</p>
          </div>
          <router-link
            v-if="item.type === notificationTypes.CHANGE_DELIVERY_ZONE"
            :to="{ name: 'account-orders' }"
            class="messages__item-content-btn base-btn _medium"
            >{{ $t("Мої замовлення") }}
          </router-link>
          <router-link
            v-else-if="item.type === notificationTypes.ORDER_DELIVERED"
            :to="{
              name: 'feedback',
              query: { order_id: getOrderId(item.message.title) },
            }"
            class="messages__item-content-btn base-btn _medium"
            >{{ $t("Оцінити") }}
          </router-link>
        </div>
      </div>
    </collapse-transition>
  </li>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";
import formatTime from "@/helpers/formatTime";
import notificationTypes from "@/constants/notificationsTypes";
import { mapActions } from "vuex";

export default {
  name: "MessageItem",
  props: {
    item: {
      type: Object,
    },
    isDesktop: Boolean,
  },
  components: {
    CollapseTransition,
  },
  data() {
    return {
      notificationTypes,
      isOpen: false,
      isUnread: !this.item.read,
      formatTime,
    };
  },
  methods: {
    ...mapActions("notifications", ["markAsRead"]),
    onToggle() {
      this.isOpen = !this.isOpen;

      if (this.isOpen && this.isUnread) {
        this.markAsRead(this.item.id).then(() => {
          this.isUnread = false;
        });
      }
    },
    getOrderId(title) {
      const x = `${title.substring(title.lastIndexOf(" ") + 1)}`;
      console.log(x, "xxx");
      return x.toString();
    },
  },
  mounted() {
    const id = this.$route.query.id;
    if (String(id) === String(this.item.id)) {
      setTimeout(() => {
        this.$scrollTo(this.$el, {
          offset: this.isDesktop ? -140 : -70,
        });
        this.onToggle();
        this.$router.replace({ query: null });
      }, 700);
    }
  },
};
</script>

<style></style>
