<template>
  <div class="orders-history" :class="{_loading: pending}">
    <BasePreloader v-if="pending || pendingMore"/>
    <div v-if="!pending && !items.length" class="orders-history__not-found">
      <BaseSvg name="ico-sad" width="6rem" height="6rem"/>
      <div class="orders-history__not-found-title">{{ $t('Ви не здійснили жодного замовлення') }}</div>
      <div class="orders-history__not-found-text">{{ $t('Перейдіть у розділ меню') }}</div>
      <router-link :to="{
                      name: 'home',
                      hash: '#catalog'
                    }" class="orders-history__not-found-btn base-btn">{{ $t('До меню') }}
      </router-link>
    </div>
    <transition name="fade">
      <ul class="orders-history__list" v-if="items.length">
        <OrdersHistoryItem v-for="(item, index) in items" :key="`${index}-${item.id}`" :item="item"/>
      </ul>
    </transition>

  </div>
</template>

<script>
  import OrdersHistoryItem from '@/components/OrdersHistoryItem'

  export default {
    name: 'OrdersHistory',
    components: {OrdersHistoryItem},
    props: {
      pending: {
        type: Boolean,
      },
      pendingMore: {
        type: Boolean,
      },
      items: {
        type: Array,
      }
    }
  }
</script>

<style>
</style>
