<template>
  <div class="messages" :class="{_loading: pending}">
    <BasePreloader v-if="pending"/>

    <div v-if="!pending && !data.length"
         class="account-item__content">
      <div class="messages__not-found">
        <BaseSvg name="ico-sad" width="6rem" height="6rem"/>
        <div class="messages__not-found-title">{{ $t('У вас немає жодного повідомлення') }}</div>
      </div>
    </div>

    <ul v-if="data.length"
        class="messages__list">
      <MessageItem v-for="item in data"
                   :isDesktop="isDesktop"
                   :key="item.id"
                   :item="item"/>
    </ul>

    <transition name="fade">
      <Pagination
          v-if="data.length && totalPages > 1"
          :totalPages="totalPages"
          :activePages="loadedPages"
          withMore
          :pendingMore="pendingMore"
          @more="onLoadMore"
          class="messages__pagination"
      />
    </transition>

  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import MessageItem from '@/components/MessageItem'
  import Pagination from '@/components/Pagination'
  import windowSizeMixin from '@/mixins/windowSizeMixin'

  export default {
    name: 'Messages',
    mixins: [windowSizeMixin],
    components: {
      Pagination,
      MessageItem
    },
    data() {
      return {
        //pending: true,
        //data: [],
        //totalPages: 1
      }
    },
    computed: {
      ...mapState('notifications', [
        'data',
        'pending',
        'pendingMore',
        'loadedPages',
        'totalPages',
        'unread_count'
      ]),
      currentPage() {
        return parseFloat(this.$route.query.page) || 1
      },
      isDesktop() {
        return this.windowSizeMixin.width > 1023
      }
    },
    methods: {
      ...mapActions('notifications', [
        'load'
      ]),
      onLoadMore() {
        const newPage = this.currentPage + 1
        this.$router.push({
          query: {page: newPage},
          params: {loadMore: true, savePosition: true},
        })
      },
      onLoad(to) {
        const isMore = !!this.$route.params.loadMore

        if (!isMore) {
          this.$scrollTo('#account-content', {
            offset: this.isDesktop ? -140 : -70,
          })
        }

        this.load({currentPage: to, isMore})
      }
    },
    created() {
      if (this.$route.query.page) {
        this.onLoad(this.currentPage)
      } else if (!this.pending) {
        this.load()
      }
    },
    watch: {
      data(to) {
        if (to && to.length) {
          const id = this.$route.query.id
          if (!id) return
          const item = this.data.some(item => String(item.id) === String(id))
          if (!item) {
            this.$router.replace({query: null})
          }
        }
      },
      currentPage(to) {
        this.onLoad(to)
      }
    }
  }
</script>

<style>
</style>