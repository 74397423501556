<template>
  <div class="account-item">
    <div class="account-item__head">
      <h3 class="account-item__title">{{ $t("Мої замовлення") }}</h3>
    </div>

    <OrdersHistory :pending="pending" :pendingMore="pendingMore" :items="items" class="account-item__content" />

    <transition name="fade">
      <Pagination
        v-if="totalPages > 1"
        :totalPages="totalPages"
        :activePages="loadedPages"
        withMore
        :pendingMore="pendingMore"
        @more="onLoadMore"
        class="account-item__pagination"
      />
    </transition>
  </div>
</template>

<script>
import OrdersHistory from "@/components/OrdersHistory";
import Pagination from "@/components/Pagination";
import { http } from "@/axios";
import windowSizeMixin from "@/mixins/windowSizeMixin";
import { mapState } from "vuex";

export default {
  name: "AccountPageOrders",
  components: { OrdersHistory, Pagination },
  mixins: [windowSizeMixin],
  data() {
    return {
      pending: false,
      pendingMore: false,
      items: [],
      loadedPages: [],
      totalPages: 1,
    };
  },
  computed: {
    ...mapState("notifications", ["firstLoad", "notificationsTotal"]),
    currentPage() {
      return parseFloat(this.$route.query.page) || 1;
    },
    isDesktop() {
      return this.windowSizeMixin.width > 1023;
    },
  },
  methods: {
    load() {
      const isMore = !!this.$route.params.loadMore;
      if (isMore) {
        this.pendingMore = true;
      } else {
        this.pending = true;
        this.$scrollTo("#account-content", {
          offset: this.isDesktop ? -140 : -70,
        });
      }
      http
        .get("/api/order/user-orders", {
          params: {
            page: this.currentPage,
            limit: 15,
          },
        })
        .then(({ data }) => {
          if (isMore) {
            this.items = this.items.concat(data.data);
            this.loadedPages.push(this.currentPage);
          } else {
            this.items = data.data;
            this.loadedPages = [this.currentPage];
          }

          this.totalPages = data.meta.last_page;
        })
        .finally(() => {
          this.pending = false;
          this.pendingMore = false;
        });
    },
    onLoadMore() {
      const newPage = this.currentPage + 1;
      this.$router.push({
        query: { page: newPage },
        params: { loadMore: true, savePosition: true },
      });
    },
  },
  created() {
    this.load();
  },
  watch: {
    notificationsTotal() {
      if (!this.firstLoad) {
        if (this.$route.query.page) {
          this.$router.push({ name: this.$route.name }).catch(() => {});
        } else {
          this.load();
        }
      }
    },
    currentPage() {
      this.load();
    },
  },
};
</script>

<style></style>
