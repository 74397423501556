<template>
  <main class="page-account container">
    <div class="page-account__inner container-inner">
      <AccountMenu class="page-account__menu"/>
      <div class="page-account__content" id="account-content">
        <transition name="fade"
                    mode="out-in">
          <router-view
                       class=""/>
        </transition>
      </div>
    </div>
  </main>
</template>

<script>
  import AccountMenu from '../../components/AccountMenu'
  import windowSizeMixin from '@/mixins/windowSizeMixin'

  export default {
    name: 'AccountPage',
    components: {AccountMenu},
    mixins: [windowSizeMixin],
    data() {
      return {}
    },
    computed: {
      isDesktop() {
        return this.windowSizeMixin.width > 1023
      }
    },
    methods: {
      toUp() {
        this.$scrollTo('#account-content', {
          offset: this.isDesktop ? -140 : -70,
        })
      }
    },
    mounted() {
      this.toUp()
    },
    watch: {
      '$route.name'() {
        this.toUp()
      }
    }
  }
</script>

<style lang="less">

</style>
