<template>
  <div class="account-item">
    <div class="account-item__head">
      <h3 class="account-item__title">{{$t('Змінити пароль')}}</h3>
    </div>

    <PasswordEdit class="account-item__content"/>
  </div>
</template>

<script>
  import PasswordEdit from '../../components/PasswordEdit'
  export default {
    name: 'AccountPagePasswordEdit',
    components: {PasswordEdit}
  }
</script>

<style>

</style>
